import React from "react";
import { withRouter } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { seconds: 5 };
  }

  componentDidMount() {
    this.timer = setInterval(this.decrementTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  decrementTimer = () => {
    this.setState(
      (prevState) => ({ seconds: prevState.seconds - 1 }),
      () => {
        if (this.state.seconds <= 0) {
          this.props.history.go(-3);
        }
      }
    );
  };

  handleGoBack = () => {
    this.props.history.go(-3);
  };

  render() {
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        textAlign: "center",
        color: "#343a40",
      },
      icon: {
        fontSize: "5rem",
        marginBottom: "20px",
        color: "#dc3545",
      },
      heading: {
        fontSize: "2.5rem",
        marginBottom: "10px",
      },
      paragraph: {
        fontSize: "1.2rem",
        marginBottom: "20px",
      },
      link: {
        color: "#007bff",
        textDecoration: "none",
        fontSize: "1.2rem",
      },
    };

    return (
      <div style={styles.container}>
        <FaExclamationTriangle style={styles.icon} />
        {/* <h1
          style={{ marginTop: "4vh", fontSize: "190px" }}
          className="display-1 fw-bold text-danger"
        >
          404
        </h1> */}
        <p className="fs-3" style={styles.heading}>
          <span className="text-danger">Oops!</span> Page not found.
        </p>
        <p className="lead" style={styles.paragraph}>
          The page you’re looking for doesn’t exist.
        </p>
        <button
          className="btn"
          onClick={this.handleGoBack}
          style={(styles.link, styles.paragraph)}
        >
          &nbsp;&nbsp;Go to Previous Page: {this.state.seconds}&nbsp;&nbsp;
        </button>
      </div>
    );
  }
}

export default withRouter(NotFoundPage);
